.issues {
  background-color: lighten($light-gray-color, 17%);
}

.issue {
  padding-bottom: $small-spacing;
  //border-bottom: 1px solid lighten($light-gray-color, 5%);

  .issue-title {
    font-weight: 700;
    text-align: center;
  }

  .issue-content {
    h2 {
      margin-bottom: 20px;
      margin-top: 20px;
    }

    a {
      color: $blue-color;
      font-weight: bold;
    }

    .issue-item {
      margin: 0 0 $small-spacing;
    }

    .issue-site {
      font-style: italic;
      color: $medium-gray-color;
    }

    i.sponsor {
      font-style: normal;
      color: $primary-color;
    }
  }

  &:last-child {
    border-bottom: 0;
    margin-bottom: 0;
  }

}

.issue-meta {
  color: $medium-gray-color;
  font-style: italic;
}

.issue-link,
.issue a {
  @extend .link;
}
.issue {
  @extend .clearfix;
}
