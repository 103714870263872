* {
  &,
  &:before,
  &:after {
    box-sizing: border-box;
  }
}

html,
body {
  width: 100%;
  height: 100%;
}

ul {
}

.clearfix {
  &:before {
    content: " ";
    display: table;
  }

  &:after {
    content: " ";
    display: table;
    clear: both;
  }

  *zoom: 1;
}

body {
  background-color: $base-background-color;
  font-weight: 400;
}

hr {
  border-top: none;
  border-bottom: 2px solid lighten($light-gray-color, 7%);
  width: 100%;
  margin: $small-spacing 0;
}

.container {
  padding: $base-spacing;
  margin-left: auto;
  margin-right: auto;
  max-width: 800px;
}

.text-center {
  text-align: center;
}

.pull-center {
  display: inline-block;
  width: 33.333%;
  text-align: center;
}


.link {
  text-decoration: underline;
  text-decoration-color: $light-gray-color;

  &:hover,
  &:focus,
  &:active {
    text-decoration-color: darken($light-gray-color, 20%);
  }
}

.site-header {
  border-top: 5px solid $light-gray-color;
  border-bottom: 1px solid lighten($light-gray-color, 5%);
  margin-bottom: $small-spacing;
  min-height: 80px;
}
.intro {
  padding-top: $base-spacing;
  padding-bottom: $base-spacing;

  .main {
    background-color: $action-color;
    color: $white-color;
    display: inline-block;
    padding: 0 .3em;
  }

  a {
    @extend .link;
  }
}


#message {
  color: $blue-color;
  @extend .text-center;
}

.logo {
  float: left;
  margin: 0 0 1em 0;
  cursor: pointer;
  text-transform: uppercase;
  letter-spacing: 0.8px;
  font-size: 28px;
  line-height: 28px;
  font-weight: 300;

  span {
    font-weight: 700;
  }
}

.nav {
  min-height: 64px;
}

.navbar {
  float: right;
  margin: 0;
  position: relative;
  padding: 0;
  pointer-events: all;
  cursor: pointer;

  li {
    display: inline-block;
    padding: 0 .6em;
  }

  a {
    @extend .link;
  }
}


.pagination {
  display: flex;
  flex-direction:row;
  align-content: space-between;

  .prev, .next {
    @extend .link;
  }

  a {
    font-size: rem(12);
  }

  .right {
    margin-left: auto;
  }
  .left {
    margin-right: auto;
  }
}

small {
  display: block;
  font-size: rem(12);
  color: darken($medium-gray-color, 10%);
}

.site-footer {
  @extend .clearfix;

  border-bottom: 5px solid $light-gray-color;
  border-top: 1px solid lighten($light-gray-color, 5%);
  margin-top: $small-spacing;

  padding: $base-spacing 0;

  a {
    @extend .link;
  }
}

.hidden {
  display: none;
}

.pull-left {
  float: left;
  width: 33.333%;
}

.pull-right {
  float: right;
}

.def-padding-top {
  padding-top: $base-spacing * 2;
}


.subscribe {
  position: relative;

  .email:focus {
    border-color: $blue-color;
    outline: none;
    box-shadow: $form-box-shadow, 0 0 5px adjust-color($blue-color, $lightness: -5%, $alpha: -0.3);
  }

  .button {
    @include position(absolute, 0 0 0 null);
    background-color: $blue-color;

    &:hover,
    &:focus,
    &:active {
      background-color: darken($blue-color, 20%);
    }
  }
}

.navbar {
  text-transform: uppercase;
}

.contact-us {
  background-color: #f7f7f7;
}
