table {
  border-collapse: collapse;
  font-feature-settings: "kern", "liga", "tnum";
  margin: $small-spacing 0;
  table-layout: fixed;
  width: 100%;
}

th {
  font-weight: 600;
  padding: $small-spacing 0;
  text-align: left;
}

td {
  padding: $small-spacing 0;
}

tr,
td,
th {
  vertical-align: middle;
}
