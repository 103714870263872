.testimonials {
  h2 {
    text-align: center;
    font-weight: 700;
  }

  box-sizing: border-box;


  .grid {
    max-width: 1200px;
    margin: 30px auto;
    padding: 0 20px;
    width: 100%;
    display: grid;
    /* Define Auto Row size */
    /*Define our columns */
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
    grid-gap: 1em;
  }

  testimonial {
    border-radius: 10px;
    padding: 20px;
    color: #fff;
    background-color: lighten($blue-color, 10%);


    .testimonial-feedback {
      font-size: 1.4rem;
      font-weight: bold;
    }

    .testimonial-author {
      text-align: center;
      padding-top: $base-spacing;
      font-style: italic;

      img {
        width: 100px;
      }

      .testimonial-author-bio {
        font-size: 0.9rem;
      }
    }
  }
}
